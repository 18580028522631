*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

html {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 400;
}

h1 {
  letter-spacing: -.05em;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 1.375;
}

@media (min-width: 640px) {
  h1 {
    font-size: 3.75rem;
    line-height: 1.25;
  }
}

h2 {
  letter-spacing: -.025em;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 1.375;
}

@media (min-width: 640px) {
  h2 {
    font-size: 2.25rem;
    line-height: 1.25;
  }
}

h3 {
  letter-spacing: -.025em;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.375;
}

h4, h5, h6 {
  letter-spacing: -.025em;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.375;
}

strong {
  letter-spacing: -.025em;
}

p {
  letter-spacing: .025em;
  line-height: 2;
}

blockquote {
  font-size: 1.125rem;
  line-height: 1.75rem;
  position: relative;
}

@media (min-width: 640px) {
  blockquote {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

blockquote:before {
  content: "";
  z-index: -10;
  height: 4rem;
  width: 4rem;
  position: absolute;
  top: -1.5rem;
  left: 0;
}

code:before, code:after {
  content: "`";
}

pre {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: .75rem;
  padding-left: 2rem;
  padding-right: 2rem;
  overflow-x: scroll;
}

pre code:before, pre code:after {
  content: "";
}

table {
  width: 100%;
}

table th, table td {
  text-align: left;
  vertical-align: top;
  padding: .75rem;
}

table p, table strong {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  font-size: 1rem;
  line-height: 1.5rem;
}

table tbody tr:nth-of-type(2n+1) {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

figure {
  max-width: -moz-fit-content;
  max-width: fit-content;
}

figcaption {
  opacity: .6;
  margin-top: .75rem;
  font-style: italic;
  font-weight: 700;
}

img ~ figcaption {
  margin-top: -.75rem;
}

:is(main h1, main h2, main h3, main h4, main h5, main h6, main p, main ul, main ol, main a, main blockquote, main code, main pre, main table, main figure, main iframe, main img, main hr):not(:last-child) {
  margin-bottom: 1.5rem;
}

:is(main p, main ul, main ol, main a, main blockquote, main code, main pre, main table, main figure, main iframe, main img, main hr) + h1, :is(main p, main ul, main ol, main a, main blockquote, main code, main pre, main table, main figure, main iframe, main img, main hr) + h2, :is(main p, main ul, main ol, main a, main blockquote, main code, main pre, main table, main figure, main iframe, main img, main hr) + h3, :is(main p, main ul, main ol, main a, main blockquote, main code, main pre, main table, main figure, main iframe, main img, main hr) + h4, :is(main p, main ul, main ol, main a, main blockquote, main code, main pre, main table, main figure, main iframe, main img, main hr) + h5, :is(main p, main ul, main ol, main a, main blockquote, main code, main pre, main table, main figure, main iframe, main img, main hr) + h6 {
  margin-top: 3rem;
}

main h2 a, main h3 a, main h4 a, main p a, main li a {
  text-underline-offset: 4px;
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

main h2 a:hover, main h3 a:hover, main h4 a:hover, main p a:hover, main li a:hover {
  --tw-text-opacity: 1;
  color: rgb(226 40 40 / var(--tw-text-opacity));
}

main ul, main ol {
  padding-left: 1.25rem;
}

main ul > li {
  margin-top: .75rem;
  line-height: 2;
  list-style-type: disc;
}

main ol > li {
  margin-top: .75rem;
  line-height: 2;
  list-style-type: decimal;
}

.preload {
  opacity: 0;
}

.preload .transition {
  transition-property: none !important;
}

.lazyload, .lazyloading {
  opacity: 0;
}

img.lazyload:not([src]) {
  visibility: hidden;
}

button#cky-btn-settings:focus, button#cky-btn-accept:focus, button#cky-btn-custom-accept:focus, button#ckyModalClose:focus, a#cky-btn-readMore:focus {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 960px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1400px;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1600px;
  }
}

.container {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 640px) {
  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1600px;
  }
}

.text-link {
  letter-spacing: -.025em;
  opacity: 1;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.75rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.text-link:hover {
  opacity: .7;
}

.text-link:after {
  height: .75rem;
  width: .75rem;
  content: "";
  background: url("arrow-right.162c8da4.svg") center no-repeat;
  margin-left: 1rem;
  display: inline-block;
}

.text-link.text-link-alt:after {
  background: url("arrow-right-alt.17b0e328.svg") center no-repeat;
}

@media (min-width: 960px) {
  .link-hover {
    position: relative;
  }

  .link-hover:after {
    height: .125rem;
    width: 100%;
    --tw-scale-x: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
    content: "";
    transition-property: transform;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    display: block;
  }

  .link-hover:hover:after, .link-hover.active:after {
    --tw-scale-x: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

.button {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  letter-spacing: -.025em;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: .375rem;
  padding: .5rem 1rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.75rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: table;
}

.button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(107 107 105 / var(--tw-bg-opacity));
}

.button:after {
  height: .75rem;
  width: .75rem;
  content: "";
  background: url("arrow-right-alt.17b0e328.svg") center no-repeat;
  margin-left: 1rem;
  display: inline-block;
}

.maintenance-table {
  display: none;
}

@media (min-width: 640px) {
  .maintenance-table {
    display: block;
  }
}

.maintenance-table th {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
}

.maintenance-table th:first-of-type {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  background-color: #0000;
  border-width: 0;
}

.maintenance-table th:not(:first-of-type) {
  text-align: center;
}

.maintenance-table th p {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-weight: 400;
}

.maintenance-table td:not(:first-of-type) {
  --tw-border-opacity: 1;
  border-left-width: 1px;
  border-right-width: 1px;
  border-color: rgb(245 245 245 / var(--tw-border-opacity));
  text-align: center;
}

.responsive-tables {
  display: block;
}

@media (min-width: 640px) {
  .responsive-tables {
    display: none;
  }
}

.responsive-tables th {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.responsive-tables th:first-of-type {
  border-top-left-radius: .375rem;
}

.responsive-tables th:last-of-type {
  border-top-right-radius: .375rem;
}

.responsive-tables td:last-of-type {
  text-align: center;
}

.half-bg-grey {
  position: relative;
}

.half-bg-grey:before {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

@media (min-width: 640px) {
  .half-bg-grey:before {
    display: block;
  }
}

.half-bg-grey:before {
  content: "";
  left: 40%;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.bottom-0 {
  bottom: 0;
}

.bottom-8 {
  bottom: 2rem;
}

.left-0 {
  left: 0;
}

.left-8 {
  left: 2rem;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.z-20 {
  z-index: 20;
}

.z-50 {
  z-index: 50;
}

.col-start-1 {
  grid-column-start: 1;
}

.row-start-2 {
  grid-row-start: 2;
}

.row-start-3 {
  grid-row-start: 3;
}

.m-auto {
  margin: auto;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-10 {
  height: 2.5rem;
}

.h-4 {
  height: 1rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.min-h-screen {
  min-height: 100vh;
}

.min-h-screen\/2 {
  min-height: 56.25vw;
}

.min-h-screen\/3 {
  min-height: 33.3333vh;
}

.w-28 {
  width: 7rem;
}

.w-8 {
  width: 2rem;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-xl {
  max-width: 36rem;
}

.flex-grow {
  flex-grow: 1;
}

.-translate-y-0 {
  --tw-translate-y: -0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-full {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-1\/3 {
  --tw-translate-x: 33.3333%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1\/3 {
  --tw-translate-y: 33.3333%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -1rem, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0);
  }
}

.animate-fadeDown {
  animation: .5s cubic-bezier(.4, 0, .2, 1) .1s forwards fadeDown;
}

.animate-fadeDown1 {
  animation: .5s cubic-bezier(.4, 0, .2, 1) .4s forwards fadeDown;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animate-fadeIn {
  animation: .5s cubic-bezier(.4, 0, .2, 1) .1s forwards fadeIn;
}

.animate-fadeIn1 {
  animation: .5s cubic-bezier(.4, 0, .2, 1) .4s forwards fadeIn;
}

@keyframes fadeLeft {
  0% {
    opacity: 0;
    transform: translate3d(1rem, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0);
  }
}

.animate-fadeLeft {
  animation: .5s cubic-bezier(.4, 0, .2, 1) .1s forwards fadeLeft;
}

.animate-fadeLeft1 {
  animation: .5s cubic-bezier(.4, 0, .2, 1) .4s forwards fadeLeft;
}

@keyframes fadeRight {
  0% {
    opacity: 0;
    transform: translate3d(-1rem, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0);
  }
}

.animate-fadeRight {
  animation: .5s cubic-bezier(.4, 0, .2, 1) .1s forwards fadeRight;
}

.animate-fadeRight1 {
  animation: .5s cubic-bezier(.4, 0, .2, 1) .4s forwards fadeRight;
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 1rem, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0);
  }
}

.animate-fadeUp {
  animation: .5s cubic-bezier(.4, 0, .2, 1) .1s forwards fadeUp;
}

.animate-fadeUp1 {
  animation: .5s cubic-bezier(.4, 0, .2, 1) .4s forwards fadeUp;
}

.resize {
  resize: both;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-1 {
  gap: .25rem;
}

.gap-16 {
  gap: 4rem;
}

.gap-8 {
  gap: 2rem;
}

.overflow-hidden {
  overflow: hidden;
}

.rounded {
  border-radius: .25rem;
}

.rounded-md {
  border-radius: .375rem;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-dashed {
  border-style: dashed;
}

.border-grey-light {
  --tw-border-opacity: 1;
  border-color: rgb(245 245 245 / var(--tw-border-opacity));
}

.bg-\[gold\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 215 0 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(20 147 224 / var(--tw-bg-opacity));
}

.bg-green {
  --tw-bg-opacity: 1;
  background-color: rgb(55 165 73 / var(--tw-bg-opacity));
}

.bg-grey {
  --tw-bg-opacity: 1;
  background-color: rgb(107 107 105 / var(--tw-bg-opacity));
}

.bg-grey-light {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.bg-orange {
  --tw-bg-opacity: 1;
  background-color: rgb(255 95 54 / var(--tw-bg-opacity));
}

.bg-purple {
  --tw-bg-opacity: 1;
  background-color: rgb(142 93 233 / var(--tw-bg-opacity));
}

.bg-red {
  --tw-bg-opacity: 1;
  background-color: rgb(226 40 40 / var(--tw-bg-opacity));
}

.bg-teal {
  --tw-bg-opacity: 1;
  background-color: rgb(40 226 162 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(226 225 40 / var(--tw-bg-opacity));
}

.object-cover {
  object-fit: cover;
}

.p-2 {
  padding: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-full {
  padding-bottom: 100%;
}

.pt-16 {
  padding-top: 4rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-normal {
  font-weight: 400;
}

.italic {
  font-style: italic;
}

.leading-loose {
  line-height: 2;
}

.leading-snug {
  line-height: 1.375;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.tracking-wide {
  letter-spacing: .025em;
}

.text-grey {
  --tw-text-opacity: 1;
  color: rgb(107 107 105 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-30 {
  opacity: .3;
}

.opacity-50 {
  opacity: .5;
}

.invert {
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.underline {
  text-underline-offset: 4px;
  text-decoration-thickness: 1px;
}

.hover\:text-red:hover {
  --tw-text-opacity: 1;
  color: rgb(226 40 40 / var(--tw-text-opacity));
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.focus\:translate-y-0:focus {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media (min-width: 640px) {
  .sm\:static {
    position: static;
  }

  .sm\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .sm\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .sm\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .sm\:col-start-1 {
    grid-column-start: 1;
  }

  .sm\:col-start-2 {
    grid-column-start: 2;
  }

  .sm\:col-start-7 {
    grid-column-start: 7;
  }

  .sm\:row-start-1 {
    grid-row-start: 1;
  }

  .sm\:row-start-2 {
    grid-row-start: 2;
  }

  .sm\:row-start-auto {
    grid-row-start: auto;
  }

  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .sm\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .sm\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .sm\:ml-8 {
    margin-left: 2rem;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:h-12 {
    height: 3rem;
  }

  .sm\:min-h-screen\/2 {
    min-height: 56.25vw;
  }

  .sm\:w-2\/5 {
    width: 40%;
  }

  .sm\:w-3\/5 {
    width: 60%;
  }

  .sm\:translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .sm\:p-8 {
    padding: 2rem;
  }

  .sm\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .sm\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .sm\:pl-16 {
    padding-left: 4rem;
  }

  .sm\:pr-16 {
    padding-right: 4rem;
  }

  .sm\:pt-20 {
    padding-top: 5rem;
  }

  .sm\:text-center {
    text-align: center;
  }

  .sm\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .sm\:leading-snug {
    line-height: 1.375;
  }
}

@media (min-width: 960px) {
  .md\:static {
    position: static;
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .md\:col-start-9 {
    grid-column-start: 9;
  }

  .md\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .md\:row-start-auto {
    grid-row-start: auto;
  }

  .md\:block {
    display: block;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-auto {
    height: auto;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:bg-transparent {
    background-color: #0000;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1400px) {
  .lg\:pb-16\/9 {
    padding-bottom: 56%;
  }
}

/*# sourceMappingURL=main.css.map */
